import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/content'
import ReadMoreText from '../components/readmore'
import Sidebar from '../components/sidebar'
import Caption from '../components/caption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export const PostTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  sidebar,
  read_before,
  read_more,
  background_image,
  background_caption,
  previous,
  section,
  current,
  total,
  next,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <article className={'section-' + section + ' post'}>
      <div className="post-head">
        <h1>
          {title}
          {subtitle ? <span className="subtitle"> {subtitle}</span> : null}
        </h1>
        {sidebar ? <Sidebar content={sidebar} className="sidebar" /> : null}
        {read_more ? (
          <ReadMoreText
            before={read_before}
            content={read_more}
            className="read-more"
          />
        ) : null}
      </div>
      <div
        className="post-body"
        style={{
          backgroundImage: 'url(' + background_image + ')',
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
        }}
      >
        <PostContent content={content} className="post__content" />
        {background_caption ? (
          <Caption caption={background_caption} className="post__caption" />
        ) : null}
        <div className="section-nav">
          <div className="section-nav__previous">
            {previous ? (
              <Link to={previous}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className="sr-only"> Previous</span>
              </Link>
            ) : null}
          </div>

          {/* <div className="section-nav__count">
            {current} of {total}
          </div> */}

          <div className="section-nav__next">
            {next ? (
              <Link to={next}>
                <span className="sr-only">Next </span>
                <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </article>
  )
}

PostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sidebar: PropTypes.string,
  read_before: PropTypes.string,
  read_more: PropTypes.string,
  background_image: PropTypes.string,
  background_caption: PropTypes.string,
  previous: PropTypes.string,
  section: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  next: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const Post = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PostTemplate
        content={post.html}
        sidebar={post.frontmatter.sidebar}
        read_before={post.frontmatter.read_before}
        read_more={post.frontmatter.read_more}
        background_image={post.frontmatter.background_image}
        background_caption={post.frontmatter.background_caption}
        previous={post.frontmatter.previous}
        section={post.frontmatter.section}
        current={post.frontmatter.current}
        total={post.frontmatter.total}
        next={post.frontmatter.next}
        contentComponent={HTMLContent}
        helmet={<Helmet title={`${post.frontmatter.title}`} />}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
      />
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Post

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        sidebar
        read_before
        read_more
        background_image
        background_caption
        previous
        section
        current
        total
        next
      }
    }
  }
`
