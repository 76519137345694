import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'

const converter = new showdown.Converter()

const Caption = ({ caption, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(caption) }}
  />
)

Caption.propTypes = {
  caption: PropTypes.node,
  className: PropTypes.string,
}

export default Caption
